.topicCard:hover {
    background-color: #5a9bc9 !important;
    color: #FFF;
    cursor: pointer;
}

.subModuleBtn {
    width: 13rem;
}

.subscriptionPanel > .MuiPaper-root {
    background-color: #FFF !important;
    border: 1px solid #d2d2d2;
}

.subscriptionPanel > .MuiPaper-root > .MuiAlert-icon {
    color: #3e3b3b !important;
    font-size: 3rem !important;
}

.subscriptionPanel > .MuiPaper-root > .MuiAlert-message > .MuiAlertTitle-root {
    font-weight: 600 !important;
}