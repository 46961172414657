.profile-msg {
    font-size: 0.65rem;
    color: #f6f6f6;
    text-align: center;
}

.sidebarContainer {
    width: "4.5rem";
    height: 100%;
}
.dropdown-menu {
    --bs-dropdown-min-width: 7rem;
}
.sideIcons > i {
    font-size: 1.5rem;
}