:root {
  ---color-payer-commercial: #73AAD1;
  /* rgba(36, 118, 170, 0.519); */
  ---color-payer-medicare: #73AAD1;
  ---color-physician: #8351d9;
  /*rgba(133, 64, 173, 0.555); */
  ---color-practice-manager: #798F44;
  /*rgba(105, 238, 69, 0.57); */
}

.report {
  padding: 10px;
  height: 80vh;
  overflow-y: scroll;
}

.report table {
  border: 1px solid grey;
  border-bottom: none;
  border-spacing: 0px;
}

.report table tr th:not(:last-of-type),
.report table tr td:not(:last-of-type) {
  border-right: 1px solid grey;
}

.tableHeader {
  justify-content: center;
  text-align: center;
}

.report table tr th,
.report table tr td {
  height: 30px;
  border-bottom: 1px solid grey;
}

.report table .sectionHeader:first-child {
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  text-align: center;
}

.report table .sectionContent:first-child {
  padding-left: 10px;
}

.report table .sectionContent:not(:first-child) {
  text-align: center;
}

.bg_payer_commercial {
  background-color: var(---color-payer-commercial);
}

.bg_payer_medicare {
  background-color: var(---color-payer-medicare);
}

.bg_physician {
  background-color: var(---color-physician);
}

.bg_practiceManager {
  background-color: var(---color-practice-manager);
}

.color_payer_commercial {
  color: var(---color-payer-commercial);
}

.color_payer_medicare {
  color: var(---color-payer-medicare);
}

.color_physician {
  color: var(---color-physician);
}

.color_practiceManager {
  color: var(---color-practice-manager);
}

.color_black {
  display: flex;
  color: #000;
  justify-content: center;
}