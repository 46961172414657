.copayTab>.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #FFFFFF !important;
    border-bottom: 2px solid #5a9bc9 !important;
    --bs-nav-tabs-link-active-bg: none !important;
    color: #5a9bc9;
}

.cardContainerSize {
    height: 70vh;
    overflow-y: scroll;
    overflow-x: clip;
}

.containerFixed {
    position: fixed;
    z-index: -1;
}

/* .copay-alert>p {
    line-height: 1px;
} */
.copay-alert {
    width: 100%;
    justify-content: center;
}
/* #2b2b2b */
.copayHeaderText {
    color: #000;
    font-size: 1.2rem;
}

.questionList {
    padding: 0;
    list-style: none;
}

.questionList>li:hover {
    color: #5a9bc9;
    cursor: pointer;
    background-color: #FAFAFA;
}

.questionList>li>a {
    text-decoration: none;
    padding: 3px;
    font-size: 0.875rem;
}

.chartButton>li {
    border: 1px solid gray;
    background-color: #FAFAFA;
    margin: 0.15rem;
    border-radius: 5px;
}

.chartButton>li>.nav-link:hover {
    color: gray;
    cursor: pointer;
}

.chartButton>li>.btnActive {
    background-color: #44779c;
    color: #FFF;
    border-radius: 0;
}

.chartButton.nav {
    --bs-nav-link-padding-x: 0.6rem;
}

.button-list.nav-link {
    color: grey;
}

.button-list.nav-link:hover {
    color: #FFF;
}

.copayAccumulator {
    height: 350px;
    overflow-y: scroll;
}

.chartBorder:first-child {
    border-right: 1px solid;
}

.togglechartBorder:nth-child(even) {
    border-right: 1px solid;
}

.chart-title-1 {
    color: #000;
    font-size: 1.1rem;
}

.chart-title-2 {
    color: #a887e0;
}

.filterPanel {
    background-color: #FAFAFA;
}

.filterCardSize {
    height: 7.7rem;
}

.chartPanel {
    width: 100%;
}

.chartSubHeader {
    background-color: #dadada;
    color: #2b2b2b;
    padding: 0.5rem;
}

.chartMetaHead {
    background-color: #d5d5d5;
    padding: 5px;
}

.chartMetaHeadTwoView{
    background-color: #d5d5d5;
    padding: 5px;
    min-height: 120px;
}

.meanContainer {
    align-self: center;
}
.meanArea {
    height: 100%;
    text-align: center;
    align-self: center;
}

.buttonToggleBg {
    background-color: #CED4DA;
    color: black
}

/* #73AAD1;  "#798F44", "#ACCE5A" f5f533*/
.Payers, .Payers:hover, .Payers:active, .Payers:focus{
    background-color: #73AAD1;  
}

.Physicians, .Physicians:hover, .Physicians:active, .Physicians:focus{
    background-color: #8351d9;
}

.Oncologists, .Oncologists:hover, .Oncologists:active, .Oncologists:focus{
    background-color: #8351d9;
}

.PracticeManagers, .PracticeManagers:hover, .PracticeManagers:active, .PracticeManagers:focus{
    background-color: #798F44;
}

.highcharts-no-data {
    font-size: 14px !important;
    color: #000 !important;
}

.question_toggle {
    position: absolute;
    writing-mode: vertical-lr;
    height: 80vh;
    padding: 0;
    margin: 0;
    left: -18px;
    border: 0;
    width: 20px;
}

.chartFullArea {
    margin-left: 30px;
    width: 98%;
}

.filterBtn {
    position: relative;
    right: 0;
}

.questionLabel {
    margin-top: 4px;
    background-color: #343232;
    box-shadow: 4px 4px 8px #8e8484;
    color: #FFF;
    border-radius: 4px;
}

.highcharts-tooltip-container .highcharts-tooltip .chartPopHoverTableContainer {
    min-width: 200px;
    max-width: 350px;
    word-break: break-all;
    background-color: red;
}

.popHoverTable {
    min-width: 200px;
    background: #FFF;
}

.popHoverTable table{
    border: 1px solid #e0e0e0;
    width: 100%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: #000000de;
    font-size: 0.875rem;
}

.popHoverTable .popHoverHeader {
    background-color: #73AAD1;
    padding: 10px;
}
.popHoverTable td {
    padding: 5px;
    
}
.popHoverTable tr {
    padding: 10px;
    border: 1px solid #e0e0e0;
}
/* .popHoverTable table */

/* .containerChartArea {
    width: 98%;
} */