/* .firstTable>.MuiDataGrid-main>.MuiDataGrid-columnHeaders {
    background-color: #73AAD1;
}

.secondTable>.MuiDataGrid-main>.MuiDataGrid-columnHeaders {
    background-color: #73AAD1;
} */

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
    align-items: baseline;
    padding-top: 5px;
}

.firstTable>.MuiDataGrid-toolbarContainer,
.secondTable>.MuiDataGrid-toolbarContainer {
    display: block !important;
    text-align: right;
}

.MuiDataGrid-menuList>li:last-child {
    display: none;
}

/* #73AAD1 */
.table_Payers>.MuiDataGrid-main>.MuiDataGrid-columnHeaders {
    background-color: #73AAD1; 
    color: #FFF;
}

.table_Physicians>.MuiDataGrid-main>.MuiDataGrid-columnHeaders {
    background-color: #8351d9;
    color: #FFF;
}

.table_Oncologists>.MuiDataGrid-main>.MuiDataGrid-columnHeaders {
    background-color: #8351d9;
    color: #FFF;
}

.table_PracticeManagers>.MuiDataGrid-main>.MuiDataGrid-columnHeaders {
    background-color: #798F44;
    color: #FFF;
}