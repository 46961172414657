.homeModules {
    z-index: -1;
}

.homeModules>div>.card>img {
    height: 145px;
}

.homeModules>div>.card>.card-body {
    height: 4rem;
}

.homeContainer {
    margin-left: 2.5rem;
}

.modulecard {
    width: 20rem;
}

.knowlegePlaceholder {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    /* //180px*/

}
.indexPosition {
    position: absolute;
    z-index: -1;
}

.knowlegePlaceholder>a {
    background: #5a9bc9;
    border-radius: 50%;
    padding: 13px;
    cursor: pointer;
    width: 3.5rem;
    float: right;
}

.ktBoat {
    width: 17rem;
    text-align: center;
    border: 1px solid #5a9bc9;
    padding: 2rem;
    border-radius: 9px;
    background-color: #FFF;
    margin: 0px 40px -8px 0px;
}